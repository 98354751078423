import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { Seperator, Space } from '@dex/bubl-dash';
import ParticipantPanel from '../../../elements/Participant/ParticipantPanel';
import UpdateStatusPanel from '../../../elements/UpdateStatus/UpdateStatusPanel';
import ConversationPanel from '../../../elements/Conversation/ConversationPanel';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';

const HelpdeskTicketsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const canCreateService = app.userCan(["createServices"]);
    const canManageParticipants = app.userCan(["updateParticipantHelpdeskTickets"]);

    return useMemo(() => (

        <>
            <Row
                gutter={'auto'}
            >

                <Col
                    className={'main'}
                >

                    <Panel
                        heading={"Ticket Details"}
                    >

                        <MetaTable
                            data={data}
                            columnA={[
                                {
                                    label: "Topic",
                                    key: "topic",
                                    format: "intl",
                                    prefix: "helpdesk.topic"
                                },
                                {
                                    label: "Issue",
                                    key: "issue",
                                    format: "intl",
                                    prefix: "helpdesk.issue",
                                    hideIfEmpty: true
                                },
                                {
                                    label: "Subject",
                                    key: "subject",
                                    hideIfEmpty: true
                                },
                                {
                                    label: "Message",
                                    key: "message",
                                    format: "nl2br",
                                    hideIfEmpty: true
                                },
                            ]}
                            columnB={[
                                {
                                    label: "Contact Person",
                                    key: "person",
                                },
                                {
                                    label: "Phone",
                                    key: "phone",
                                },
                            ]}
                            columnC={[
                                {
                                    label: "Attachments",
                                    key: "attachments",
                                    format: "file",
                                },
                                data.remarks && {
                                    label: "Remarks",
                                    key: "remarks",
                                    format: "nl2br",
                                },
                            ].filter(Boolean)}
                        />

                        <Space />

                        {data.issues && data.issues[data.issue].map((issue, index) => (
                            <div key={index}>
                                {issue}
                            </div>
                        ))}

                        {canCreateService && (data.status === "open" || data.status === "unresolved") && !data.service_id &&
                            <>
                                <Line />
                                <Button
                                    label={"Create Service Request"}
                                    type={"primary"}
                                    path={`/services/create?ticket_id=${data.id}&merchant_id=${data.merchant_id}&person=${data.person}&phone=${data.phone}&roll_size=${data.roll_size}`}
                                />
                            </>
                        }

                        {data.service &&
                            <>
                                <Seperator
                                    heading={"Service Request"}
                                    top={"auto"}
                                    bottom={"auto"}
                                />

                                <MetaTable
                                    data={data.service}
                                    columnA={[
                                        {
                                            label: "Service ID",
                                            key: "this",
                                            format: "modelLink",
                                        },
                                    ]}
                                    columnB={[
                                        {
                                            label: "Status",
                                            key: "status",
                                            format: "status",
                                            prefix: "service.status"
                                        },
                                    ]}
                                    columnC={[
                                        {
                                            label: "Type",
                                            key: "type",
                                            format: "intl",
                                            prefix: "service.type"
                                        },
                                    ]}
                                />
                            </>
                        }

                        <ConversationPanel
                            data={data}
                        />

                    </Panel>

                    <Space />

                    <UpdateStatusPanel
                        data={data}
                    />

                </Col>

                <Col className={'side'}>

                    {data.merchant &&
                        <>
                            <Panel
                                heading={"Merchant"}
                            >

                                <MetaTable
                                    data={data}
                                    columnSize={{ xs: 24 }}
                                    columnA={[
                                        {
                                            label: "Merchant",
                                            key: "merchant",
                                            format: "modelLink",
                                            hideIfEmpty: true
                                        },
                                        {
                                            label: "Company Name",
                                            key: "company_name",
                                            hideIfEmpty: true
                                        },
                                        {
                                            label: "Trading Name",
                                            key: "trading_name",
                                            hideIfEmpty: true
                                        },
                                        {
                                            label: "Serial Number",
                                            key: "serial",
                                            hideIfEmpty: true
                                        },
                                        {
                                            label: "MID",
                                            key: "mid",
                                            hideIfEmpty: true,
                                        },
                                        {
                                            label: "TID",
                                            key: "tid",
                                            hideIfEmpty: true
                                        },
                                    ]}
                                />

                            </Panel>
                            <Space />
                        </>
                    }

                    {canManageParticipants && (

                        <ParticipantPanel
                            data={data}
                        />

                    )}
                </Col>

            </Row>

        </>

    ), [data]);

}

export default HelpdeskTicketsSingleView;
