import React, { useMemo } from 'react';
import { Col, DataTable, DataTableCell, InputTableRow, Line, Row, Space, useApp } from '@dex/bubl-dash';
import { formatNum, nl2br } from '@dex/bubl-helpers';

const ProformaInvoiceLineItems: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();


    const columns = [
        {
            label: "Description",
            key: "description",
        },
        data.has_sst && {
            label: "SST (%)",
            key: "value_sst",
            alignMiddle: true,
        },
        {
            label: "Quantity",
            key: "quantity",
            alignMiddle: true,
        },
        {
            label: "Subtotal",
            key: "value",
            alignEnd: true,
        },
    ].filter(Boolean);

    const colSpan = data.has_sst ? 3 : 2;

    return useMemo(() => (

        <>

            <DataTable
                data={null}
                columns={columns}
                className={"mini-table invoice-table"}
            >

                {data.rows.map((row: any, index: number) => (
                    <InputTableRow className={"invoice-line-item"}>

                        <DataTableCell style={{ width: "60%" }} className={"invoice-line-desc"}>

                            {row.description ? app.formatValue("nl2br", {}, row.description) : ""}

                            <ProformaInvoiceLineItemsBreakdown
                                has_sst={data.has_sst}
                                items={row.breakdown_items}
                            />

                        </DataTableCell>

                        {data.has_sst &&
                            <DataTableCell alignMiddle className={"invoice-line-quantity"}>
                                {(row.breakdown_items && row.breakdown_items.length > 0) ? '' : (row.value_sst ? `${row.value_sst}` : '')}
                            </DataTableCell>
                        }

                        <DataTableCell alignMiddle className={"invoice-line-quantity"}>
                            {row.quantity}
                        </DataTableCell>

                        <DataTableCell alignEnd className={"invoice-line-value"}>
                            {formatNum(row.value, 2, "")}
                        </DataTableCell>

                    </InputTableRow>
                ))}

                <InputTableRow>

                    <DataTableCell colSpan={colSpan} alignEnd className="invoice-line-label">
                        Subtotal (Excluding Tax)
                    </DataTableCell>

                    <DataTableCell alignEnd>
                        {formatNum(data.subtotal, 2, "")}
                    </DataTableCell>

                </InputTableRow>

                {data.has_sst &&
                    <InputTableRow>

                        <DataTableCell colSpan={colSpan} alignEnd className="invoice-line-label">
                            SST Total
                        </DataTableCell>

                        <DataTableCell alignEnd>
                            {formatNum(data.sst_total, 2, "")}
                        </DataTableCell>

                    </InputTableRow>
                }

                {/* {data.sst_summary &&
                    <InputTableRow>
                        <DataTableCell colSpan={colSpan} alignEnd className="invoice-line-label">
                            6% SST:
                            <br />
                            8% SST:
                        </DataTableCell>
                        <DataTableCell alignEnd>
                            {" RM " + formatNum(data.sst_summary['6'], 2, "")}
                            <br />
                            {" RM " + formatNum(data.sst_summary['8'], 2, "")}
                        </DataTableCell>
                    </InputTableRow>
                } */}

                <InputTableRow>

                    <DataTableCell colSpan={colSpan} alignEnd className="invoice-line-label">
                        Refundable Deposit
                    </DataTableCell>

                    <DataTableCell alignEnd>
                        {formatNum(data.deposit, 2, "")}
                    </DataTableCell>

                </InputTableRow>

                <InputTableRow>

                    <DataTableCell colSpan={colSpan} alignEnd className="invoice-line-label">
                        <strong>Total</strong>
                    </DataTableCell>

                    <DataTableCell alignEnd>
                        <strong>{formatNum(data.total, 2, "RM ")}</strong>
                    </DataTableCell>

                </InputTableRow>

                {data.adjustment === true &&
                    <>
                        <InputTableRow>

                            <DataTableCell colSpan={colSpan} alignEnd className="invoice-line-label">
                                {data.adjustment_label}
                            </DataTableCell>

                            <DataTableCell alignEnd>
                                {formatNum(data.adjustment_amount, 2, "RM ")}
                            </DataTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <DataTableCell colSpan={colSpan} alignEnd className="invoice-line-label">
                                <strong>Amount Payable</strong>
                            </DataTableCell>

                            <DataTableCell alignEnd>
                                <strong>{formatNum(data.amount_payable, 2, "RM ")}</strong>
                            </DataTableCell>

                        </InputTableRow>
                    </>
                }

            </DataTable>

            {data.has_sst &&
                <Row justify='end'>
                    <Col col={{ xs: 24, md: 12, lg: 8 }}>
                        <Space />
                        {((data.sst_summary['6_amount'] != "0.00") || (data.sst_summary['8_amount'] != "0.00")) &&
                            <ProformaInvoiceLineItemsTaxSummary
                                data={data}
                            />
                        }
                    </Col>
                </Row>
            }

        </>

    ), [data]);

}

const ProformaInvoiceLineItemsBreakdown: React.FC<any> = (props: any) => {

    const { items, has_sst } = props;

    if (!items || items.length === 0) return <></>;

    return (

        <>
            <Line top={"small"} bottom={"small"} />

            <Row className='invoice-breakdown-items' gutter={4} edge>

                {items.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                        <Col col={{ xs: 16 }}>
                            {item.description}:
                        </Col>
                        <Col col={{ xs: 8 }} align='end'>
                            {formatNum(item.amount, 2, "")}
                            {has_sst && item.amount_sst && item.amount_sst !== "0%" && (
                                ` (SST: ${item.amount_sst})`
                            )}
                        </Col>
                    </React.Fragment>
                ))}

            </Row>

        </>

    );

}

const ProformaInvoiceLineItemsTaxSummary: React.FC<any> = (props: any) => {

    const { data } = props;

    const columns = [
        {
            label: "Tax Summary",
            key: "summary",
            alignMiddle: true,
        },
        {
            label: "Amount (RM)",
            key: "amount",
            alignMiddle: true,
        },
        {
            label: "Tax (RM)",
            key: "tax",
            alignMiddle: true,
        },
    ].filter(Boolean);

    return (

        <>
            <DataTable
                data={null}
                columns={columns}
                className={"mini-table invoice-table"}
            >
                {data.sst_summary['6_amount'] != "0.00" &&
                    <InputTableRow>
                        <DataTableCell alignMiddle>
                            SST 6%
                        </DataTableCell>
                        <DataTableCell alignMiddle>
                            {formatNum(data.sst_summary['6_amount'], 2, "")}
                        </DataTableCell>
                        <DataTableCell alignMiddle>
                            {formatNum(data.sst_summary[6], 2, "")}

                        </DataTableCell>
                    </InputTableRow>
                }

                {data.sst_summary['8_amount'] != "0.00" &&
                    <InputTableRow>
                        <DataTableCell alignMiddle>
                            SST 8%
                        </DataTableCell>
                        <DataTableCell alignMiddle>
                            {formatNum(data.sst_summary['8_amount'], 2, "")}
                        </DataTableCell>
                        <DataTableCell alignMiddle>
                            {formatNum(data.sst_summary[8], 2, "")}
                        </DataTableCell>
                    </InputTableRow>
                }

            </DataTable>

        </>

    );

}

export default ProformaInvoiceLineItems;
export { ProformaInvoiceLineItemsBreakdown };